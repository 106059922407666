import type { NextPage } from "next";

import React from "react";
import Typography from "@mui/material/Typography";
import { Page, HeaderComponent, FooterComponent } from "../components";
import { usePreviousTestRunsQuery, useUpcomingTestsQuery } from "../generated/api-client";
import {
  Alert,
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useRouter } from "next/router";
import {
  ArrowForwardIos as ArrowForwardIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  Assignment as AssignmentIcon,
} from "@mui/icons-material";
import { MainStyle } from "../layouts/AppLayout/styles";

const Schedule: NextPage = () => {
  const router = useRouter();
  const [{ fetching: loading, error, data }] = useUpcomingTestsQuery();

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4">Schema</Typography>
      {loading && (
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      )}
      {data && (
        <List>
          {data.upcomingTests.map((test) => (
            <ListItem
              disablePadding
              key={test.id}
              onClick={() => router.push(`/site/${test.site.id}/test/${test.id}`)}
            >
              <ListItemButton>
                <ListItemIcon>
                  {test.submittedOn ? <AssignmentTurnedInIcon /> : <AssignmentIcon />}
                </ListItemIcon>
                <ListItemText
                  primary={test.site.name}
                  secondary={new Date(test.date).toDateString()}
                />
                <ArrowForwardIcon />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
      {data?.upcomingTests.length == 0 && (
        <Alert variant="outlined" severity="info">
          Inga kommande tester
        </Alert>
      )}
    </Box>
  );
};

const Previously: NextPage = () => {
  const router = useRouter();
  const [{ fetching: loading, data, error }] = usePreviousTestRunsQuery();

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4">Tidigare</Typography>
      {loading && (
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      )}
      {data && (
        <List>
          {data.previousTestRuns.map((test) => (
            <ListItem
              disablePadding
              key={test.id}
              onClick={() => {
                router.push(`/site/${test.site.id}/test/${test.id}`);
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  {test.submittedOn ? <AssignmentTurnedInIcon /> : <AssignmentIcon />}
                </ListItemIcon>
                <ListItemText
                  primary={test.site.name}
                  secondary={new Date(test.date).toDateString()}
                />
                <ArrowForwardIcon />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
      {data?.previousTestRuns.length == 0 && (
        <Alert variant="outlined" severity="info">
          Inga tidigare tester
        </Alert>
      )}
    </Box>
  );
};

export default function Overview() {
  return (
    <Page title="Schema">
      <HeaderComponent title="Översikt" hideOverviewButton />
      <MainStyle style={{ overflow: "scroll" }}>
        <Schedule />
        <Previously />
      </MainStyle>
      <FooterComponent />
    </Page>
  );
}
